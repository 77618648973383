@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "mixin";
@import "owl.theme";
@import "details";

span.form-control {
    border: none;
    @include box-shadow(none);
}

.preview {
    position: relative;
    width: 200px;
    height: 200px;

    &:hover {
        div {
            @include opacity(100);
            @include transition(all 0.3s ease-out);
        }
    }

    div {
        @include transition(all 0.5s ease-out);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 101;
        width: 100%;
        background-color: rgba(20,20,50,1);
        color: white;
        padding: 10px;
        @include opacity(0);
    }

    img {
        border: 1px solid #fc8c84;
        padding: 2px;
        position: absolute;
        z-index: 100;
        width: 200px;
        height: 200px;
    }
}


/*Filter styles*/
.saturate {-webkit-filter: saturate(3); filter: saturate(3);}
.grayscale {-webkit-filter: grayscale(100%); filter: grayscale(100%);}
.contrast {-webkit-filter: contrast(160%); filter: contrast(160%);}
.brightness {-webkit-filter: brightness(0.25); filter: brightness(0.25);}
.blur {-webkit-filter: blur(3px); filter: blur(3px);}
.invert {-webkit-filter: invert(50%); filter: invert(50%);}
.sepia {-webkit-filter: sepia(100%); filter: sepia(100%);}
.huerotate {-webkit-filter: hue-rotate(180deg); filter: hue-rotate(180deg);}
.rss.opacity {-webkit-filter: opacity(50%); filter: opacity(50%);}