/*
* 	Owl Carousel Owl Demo Theme
*	v1.24
*/

.owl-theme {

    @include clearfix;

    .owl-wrapper-outer {
        .item {
            //padding: 10px 5px;
        }
    }

    .owl-controls {
        //margin-top: 5px;
        //margin-bottom: 10px;


        .owl-buttons {
            div.owl-pagination,
            div.owl-prev {
                display: inline-block;
            }

            div.owl-prev,
            div.owl-next {
                color: #9e9e9e;
                display: inline-block;
                zoom: 1;
                *display: inline; /*IE7 life-saver */
                //margin: 5px;
                //padding: 3px 10px;
                font-size: 16px;
                @include opacity(.5);
            }
        }

        /* Clickable class fix problem with hover on touch devices */
        /* Use it for non-touch hover action */
        &.clickable .owl-buttons div:hover {
            @include opacity(1);
            text-decoration: none;
        }

        /* Styling Pagination*/

        .owl-page {
            display: inline-block;
            zoom: 1;
            *display: inline; /*IE7 life-saver */

            span {
                display: block;
                width: 12px;
                height: 12px;
                margin: 0 7px;
                //@include opacity(0.5);
                @include border-radius(20px);
                background: #9e9e9e;
            }
        }

        .owl-page.active span,
        &.clickable .owl-page:hover span {
            @include opacity(1);
            background: #ed1c24;
        }

        /* If PaginationNumbers is true */

        .owl-page span.owl-numbers {
            height: auto;
            width: auto;
            color: #FFF;
            padding: 2px 10px;
            font-size: 12px;
            @include border-radius(30px);
        }
    }
}


/* preloading images */
.owl-item.loading{
    min-height: 150px;
    background: url(../img/AjaxLoader.gif) no-repeat center center
}