
@mixin round-img($size) {
    border-radius: 50%;
    overflow: hidden;
    width: $size;
    height: $size;
    margin-right: 10px;

    img {
        display: block;
        width: $size;
        height: $size;
    }
}

@mixin icon-btn($width,$height) {
    width: $width;
    height: $height;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto $height;
    @include filter(contrast(0.5));
    @include transition(All ease-out 0.3s);

    &:hover, &:visited {
        @include filter(contrast(1));
    }

    &:active {
        @include filter(contrast(0.8));
    }
}

body {
    background: #dbeeed;
    font-family: 'Roboto', sans-serif;
    @media (min-width: $screen-sm-max) {
        overflow-x: hidden;
    }
    a {
        color: inherit;

        &:hover, &:active, &:focus {
            //color: inherit;
            text-decoration: none;
        }
    }
    p {
        //span { color: #FFFFFF; }
        padding: 0;
        margin: 0;
    }

    > .logo {

        text-align: center;

        .btn {
            margin: 5px 12px;
            padding-top: 15px;
            padding-bottom: 10px;
            width: 160px;

        }

        .pull-right {
            padding-top: 22px;
            font-size: 14pt;
        }

        img {
            margin: 22px 0 60px 0;
            @include opacity(.9);

            @media (max-width: $screen-sm-max) {
                margin: 10px 0;
            }
        }
    }

}


#content {
    margin-top: 15px;
    margin-bottom: 10px;

    .block-all {
        background-color: #FFFFFF;
        @include box-shadow(0 2px 8px 0 rgba(0,0,0,0.5));
        @include border-radius(3px);
        padding: 20px 0;
    }
}


.redline {

    //background: url('../img/logo.png') 15px 5px no-repeat;
    min-height: 60px;
    margin-top: 10px;
    margin-bottom: 60px;
    //background: #c2575f;

    .container {
        background-size: auto 52px;

        .mainlogo {
            img {
                width: 380px;
                padding-top: 5px;

                @media (max-width: $screen-sm-max) {
                    width: 100%;
                    margin: 20px 0;
                }
            }
        }

        .chx {
            width: 330px;
            margin: 0 60px;

            @media (max-width: $screen-sm-max) {
                width: 100%;
                margin: 0;
            }
        }

        .pull-right {
            line-height: 60px;
            a {
                color: inherit;
                text-decoration: underline;
            }
        }

    }
}

.chx {
    @include clearfix;
    padding-top: 0;

    @media (max-width: $screen-sm-max) {
        width: 100%;
        margin: 20px 0;
    }

    [type="checkbox"] {

        visibility: hidden;
        position: absolute;
        top: 0;

        & + label {
            float: left;
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 62px;
            max-height: 100%;
            cursor: pointer;
            @include filter(drop-shadow(0 1px 2px rgba(0, 0, 0, .5)));
            @include transition(All .3s ease-in)
        }

        &#chxtext + label {
            background: transparent url(../img/text-header.png) center top no-repeat;
            background-size: auto 60px;
        }

        &#chxphoto + label {
            background: transparent url(../img/photo-header.png) center top no-repeat;
            background-size: auto 60px;
        }

        &#chxvideo + label {
            background: transparent url(../img/video-header.png) center top no-repeat;
            background-size: auto 60px;
        }

        /* checkbox aspect */
        &:not(:checked) + label {
            @include opacity(0.5);
        }

        &:checked + label, & + label:hover {
            @include opacity(1);
        }

    }
}

#search_form {

    margin-top: 14px;
    margin-bottom: 32px;

    position: relative;
    z-index: 10;

    > .chx {
        position: absolute;
        top: 0;
        left: 40%;
        z-index: 20;
        width: 300px;
        @include clearfix();

        @media (max-width: $screen-sm-max) {
            position: static;
            width: 100%;
            margin-bottom: 100px;
        }
    }

    .form-group {
        margin-bottom: 24px;
    }

    .underline {
        margin-top: 20px;
        height: 16px;
        background: rgba(133, 0, 8, 1);
        @include box-shadow(0 2px 4px 0 rgba(0,0,0,.5));
    }

    .contact-selected {
        float: left;
        background: #9e9e9e;
        color: #FFFFFF;
        margin: 5px 5px 0 0;
        padding: 2px 5px;
        @include border-radius(5px);
        @include box-shadow(2px 2px 4px -2px rgba(0,0,0,.5));
    }

    .search {
        z-index: 10;
        margin: 0 30px;

        .form-control,.input-group-btn .btn {
            height: 45px;
        }

        .input-group-btn .btn {
            background: rgba(133, 0, 8, 1);
            color: #FFFFFF;
            margin: 0;
            @include transition(All ease-out .2s );

            &:hover, &:active {
                background: rgba(133, 0, 8, 0.5);
                border-color: rgba(133, 0, 8, 0.5);
            }

            span.glyphicon {
                font-size: 24px;
            }
        }
    }
}

span, input[type=file] {
    &.form-control {
        background: inherit;
    }
}

.form-control {
    padding-left: 15px;
    border: 2px solid #850009;
    background: rgba(255,255,255,.8);
    @include box-shadow(inset 1px 2px 4px 2px rgba(0, 0, 0, .3));
}

.top5 {

    margin-top: 38px;
    width: 100%;
    height: 250px;
    overflow-y: hidden;

    &::-webkit-scrollbar-track
    {
        border: 1px solid black;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar
    {
        width: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: #000000;
    }

    @media (max-width: $screen-sm-max) {
        .row {
            text-align: justify;
        }
    }

    .row {
        white-space: nowrap;
        width: 100%;
        text-align: center;
    }


    .col {
        display: inline-block;
        margin-right: 10px;
        margin-left: 10px;
        padding: 0 auto;
        @include border-radius(3px);

        img {
            display: inline-flex;
            width: auto;
            height: 250px;
        }

        &:last-child {
            @include clearfix;
        }
    }




    /*@media (max-width: $screen-sm-max) {
        overflow-x: scroll;
        width: 100%;

        .row {
            width: 500%;
            .col-md-2,.col {
                width: auto;
                height: auto;
                padding: 0;
                margin: 0;
                margin-right: 10px;
            }
        }
    }

    .col-md-2 {
        height: 18vw;
    }

    .col {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: #000000;
        @include border-radius(3px);
        width: 18%;
        height: 17vw;
        overflow: hidden;
        margin-right: 1%;
        margin-left: 1%;
        float: left;
        padding: 0 auto;
        &:last-child {
            @include clearfix;
        }

    }*/

    video, img {
        max-width: 100%;
        max-height: 100%;
    }
}

.slider-box {

    @include border-bottom-radius(3px);
    @include border-top-radius(3px);
    clear: right;

    @media (max-width: $screen-sm-max) {
        &.pull-right {
            float: none !important;
        }
    }

    > article {
        min-height: 550px;
        margin-bottom: 10px;
        padding: 10px;
        background: #c1c1c1;
        @include box-shadow(inset 0 0 6px rgba(0, 0, 0, .7), 0 0 6px -2px rgba(0, 0, 0, .7));
        @include border-radius(2px);
        @include clearfix;

        > header {
            @include clearfix;
            padding: 0 10px;

            * {
                line-height: 24pt;
            }

            p {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            a {
                text-decoration: underline;
            }
        }

        > section {
            text-transform: uppercase;
            display: block;
            padding: 20px 10px;
            font-size: 14px;
            line-height: 26px;


        }

        hr {
            margin: 12px 0;
            border-top: 1px solid gray;
            @include opacity(.5);
        }

        > footer {
            //
        }
    }
}

.view-box > article {
    position: relative;
    z-index: 10;
    background: #c1c1c1;
    border: none;
    margin-bottom: 10px;
    padding: 15px;

    @include border-bottom-radius(3px);
    @include border-top-radius(3px);
    @include box-shadow(0 2px 8px 0 rgba(0, 0, 0, 0.5));
    @include clearfix;

    > header {
        a {
            position: absolute;
            top: -19px;
            right: -19px;
            z-index: 20;
            background: url('../img/remove.png') center center no-repeat;
            @include icon-btn(38px,38px);
        }
    }

    > section {
        overflow: hidden;
        height: 404px;
    }

    > footer {
        margin-top: 10px;

        .row {
            //height: 160px;
            overflow: hidden;
        }
    }

}


.widget-box, .view-box, .slider-box {
    margin-bottom: 20px;
}

.widget-box, .view-box {
    > article section > a {
        &.video, &.photo {

            display: block;
            background-color: transparent;
            background-color: rgba(255,255,255,.5);

            height: 100%;

            img,video {
                display: block;
                margin: auto;
                max-width: 100%;
                height: 100%;
                background: #000000;
            }

            @media (max-width: $screen-sm-max) {
                img,video {
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
}

.widget-box { //, .view-box
    > article {
        section {
            overflow: hidden;
            > a {

                position: relative;
                &::before {
                    content: '';
                    z-index: 20;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-repeat: no-repeat;
                    background-position: 10px 10px;
                    background-color: rgba(0, 0, 0, 0);
                    background-size: auto 60px;
                    @include opacity(0.5);
                    @include transition(All ease-out 0.3s);
                }

                &:hover {
                    &::before {
                        @include opacity(1);
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }

                &:active {
                    &::before {
                        @include opacity(0.8);
                        background-color: rgba(0, 0, 0, 0.8);
                    }
                }

                //&.text {&::before {background-image: url(../img/text.png);}}
                &.video {&::before {background-image: url(../img/video.png);}}
                &.photo {&::before {background-image: url(../img/photo.png);}}
                &.audio {&::before {background: url(../img/video.png) left top no-repeat;}}
                &.text {&::before {background: url(../img/text.png) left top no-repeat;}}

                &.text, &.audio {
                    display: block;
                    padding: 10px 15px;
                    height: 260px;

                    &::before {
                        position: static;
                        content: '';
                        display: block;
                        float: left;
                        margin-right: 10px;
                        width: 90px;
                        height: 60px;
                        background-size: auto 60px;
                    }
                }

                p.notes {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 20;
                    margin: 0;
                    padding: 5px 10px;
                    padding-bottom: 10px;
                    color: #FFFFFF;
                    background: #000;
                    background: rgba(0, 0, 0, .7);
                }
            }
        }
    }
}


.widget-box > article {
    position: relative;
    z-index: 10;
    overflow: hidden;
    border: none;
    margin-bottom: 10px;
    background: #c1c1c1;

    @include border-bottom-radius(3px);
    @include border-top-radius(3px);
    @include box-shadow(0 2px 8px 0 rgba(0,0,0,0.5));

    @media (min-width: $screen-md-min) {
        height: 260px;

        section {
            height: 200px;
        }
    }

    > section {
        overflow: hidden;
        // background: #FFFFFF;
        z-index: 10;

    }

    > footer {
        width: 100%;
        height: auto;
        padding: 5px;
        z-index: 20;

        p { line-height: 20px; margin-top: 5px;}

    }
}

.nav-pills {

    li {
        width: 24%;
        text-align: center;
        color: #850009 !important;
        text-transform: uppercase;
        font-weight: 500;

        &.active {
            a {
                background: #dddddd !important;
                color: #850009 !important;
                font-weight: 500;
            }
        }
    }
}

.btn {

    @include border-radius(2px);
    font-weight: 500;
    text-transform: uppercase;

    &-danger {
        background: #850009;
        color: #FFFFFF;

        &-plus {
            background: none;
            color: #850009;
            border: 2px solid #850009;
        }
    }

    &-tab {
        text-transform: uppercase;
        font-weight: 600;
        color: #850009;
        background: transparent;
    }

    &-buy {
        padding: 12px 0;
        background: #dddddd;
        color: #850009;

        @include transition(All .3s ease-out);

        &:hover {
            @include opacity(0.8);
        }
    }
}

.basket {
    background-image: url(../img/basket.png);
    color: transparent;
    @include icon-btn(52px,52px);
    font-size: 24px;
    padding-left: 22px;

    &:hover, &:focus, &:active {
        color: #FFFFFF;
    }

}

.basket-block {

    position: relative;
    display: block;

    .basket-flip {
        @include transform(scaleX(-1));
        @include filter(FlipH);
        @include icon-btn(52px,52px);
        color: transparent;
        padding-left: 15px;
        font-weight: 600;
        background-image: url(../img/basket.png);
    }

    span {
        @include border-radius(50%);
        @include box-shadow(0 1px 2px 0 rgba(0, 0, 0, .5));
        position: absolute;
        top: -8px;
        left: 12px;
        padding-top: 5px;
        background: #FFFFFF;
        text-align: center;
        font-size: 14px;
        line-height: 14px;
        display: block;
        width: 24px;
        height: 24px;
    }
}

.trash {
    background-image: url(../img/trash.png);
    @include icon-btn(48px,48px);
}

.download {
    background-image: url(../img/download.png);
    @include icon-btn(48px,48px);
}

.no-side-padding {
    padding-left: 0;
    padding-right: 0;

    @media (max-width: $screen-sm-max) {
        padding-left: 10px;
        padding-right: 10px;
    }
}


.side {

    header {
        @include clearfix;
        color: #FFFFFF;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        padding: 3px 15px;
        background-color: #850009;
    }

    section {
        margin-top: 5px;
        overflow: hidden;
        position: relative;
        background: #000;

        > a {
            max-height: 260px;
            min-height: 17vw;
            display: block;

            img, video {
                width: 100%;
                height: auto;
                max-height: 100%;
                margin: auto;

            }

            @media (max-width: $screen-sm-max) {
                max-height: 100%;
                min-height: 260px;

                img, video {
                    min-height: 100vw;
                    height: 100%;
                }
            }
        }

        p.content {
            padding: 15px;
            height: 260px;
            font-size: 14px;
            color: #FFFFFF;
            background: #5e5e5e url(../img/text.png) center center no-repeat;
            background-size: 80% auto;
        }

        > form .trash {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        > div {
            height: 52px;
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            z-index: 10;
            background: #000000;
            background: rgba(0,0,0,.8);

            > div {
                color: #FFFFFF;
                &.title {
                    padding-top: 8px;
                }
            }
        }
    }
}

.modal-dialog {
    z-index: 9999;
}

.round {
    @include round-img(52px);
}

.autocomplete-suggestions {
    background: #FFFFFF;
    border: 1px solid #850009;
    @include border-bottom-radius(4px);

    .autocomplete-suggestion {
        padding: 5px 10px;

        &.autocomplete-selected {
            background: #850009;
            color: #FFFFFF;
            font-weight: 600;
        }
    }
}

.well {
    position: relative;
    background-color: #ebeeee;
    .remove {
        position: absolute;
        top: -19px;
        right: -19px;
        z-index: 20;
        background: url('../img/remove.png') center center no-repeat;
        @include icon-btn(38px,38px);
    }
}